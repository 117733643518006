import { Injectable } from '@angular/core';

import { DataPage } from '@app/models/data-page.model';

@Injectable({
    providedIn: 'root',
})
export class DataPageService {
    loading: boolean;

    constructor() {
        this.loading = false;
    }

    get statusLoading(): boolean {
        return this.loading;
    }

    set statusLoading(status: boolean) {
        this.loading = status;
    }

    dataSourceFill(
        newData: any[],
        dataPage: DataPage,
        sum: number = 10
    ): DataPage {
        if (!newData) return dataPage;

        if (newData.length != 0) {
            dataPage.skip += sum;
            dataPage.dataSource = [...dataPage.dataSource, ...newData];
        } else dataPage.scrollEnd = true;

        return dataPage;
    }
}
